<template>
  <div>
    <h2 v-html="$t('search_label_visualization_card')"></h2>
    <!-- Reduziert -->
    <v-switch
      id="search_label_visualization_card_reduced"
      v-model="searchCardStore.reduced"
      color="primary"
      :label="$t('search_label_visualization_card_reduced')"
      @keyup.enter="search"
    ></v-switch>
    <!-- Mit Verkehrsmilch -->
    <v-switch
      id="search_label_visualization_card_with_milk_transport"
      v-model="searchCardStore.withMarketedMilk"
      color="primary"
      :label="$t('search_label_visualization_card_with_milk_transport')"
      @keyup.enter="search"
    ></v-switch>
    <!-- Einlieferungen von -->
    <DbmDatePicker v-model="searchCardStore.receivedFrom" :dateLabel="$t('search_label_visualization_card_received_from')" :clearable="true" />
    <!-- Einlieferungen bis -->
    <DbmDatePicker v-model="searchCardStore.receivedUntil" :dateLabel="$t('search_label_visualization_card_received_until')" :clearable="true" />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchLabelVisualizationStore } from '@/store/SearchLabelVisualizationStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchLabelVisualization',
  extends: baseSearchVue,
  data() {
    return {}
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchLabelVisualizationStore(), true)
  }
})
</script>
