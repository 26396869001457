import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchLabelVisualizationStore = defineStore('searchLabelVisualizationStore', {
  state: () => ({
    reduced: true as boolean | null,
    withMarketedMilk: null as string | null,
    receivedFrom: null as string | null,
    receivedUntil: null as string | null,
    items: [
      { key: 'reduced', operation: ':' },
      { key: 'withMarketedMilk', operation: ':' },
      { key: 'receivedFrom', operation: ':' },
      { key: 'receivedUntil', operation: ':' }
    ] as TermItemDefinition[]
  })
})
